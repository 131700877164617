<template>
  <div class="">
    <UploadFile
      @file-select="onFileChanged"
      @clear-file="$emit('clear-file')"
      :accept="accept"
      :placeHolder="currentFile"
      :hide-delete-icon="false"
      label-button="Upload Video"
    />
  </div>
</template>

<script>
import UploadFile from '@/core/components/UploadFileAttachLink/UploadFile'
import { VideoUploadService } from '@/core/services'

export default {
  name: 'UploadVideo',
  components: {
    UploadFile
  },
  data: function () {
    return {
      currentFile: ''
    }
  },
  props: {
    fileName: {
      type: [Number, String]
    },
    accept: {
      type: String,
      default: 'video/*'
    }
  },
  mounted() {
    this.currentFile = this.fileName
  },
  watch: {
    fileName(newFileName) {
      this.currentFile = newFileName
    }
  },
  methods: {
    async onFileChanged(files) {
      let file
      const isVideo = files[0].type.startsWith('video/')
      if (isVideo) {
        const customData = await VideoUploadService.createFileNameData(files[0])
        file = {
          url: URL.createObjectURL(files[0]),
          objectURL: URL.createObjectURL(new Blob([files[0]])),
          file: files[0],
          index_file: 1,
          type_file: 'media',
          filename: files[0].name,
          customData: customData,
          label: customData.label,
          isVideo: true,
          video_environment: 'create'
        }
        this.currentFile = customData.label
        this.$emit('on-filed-changed', file)
      } else {
        this.$bvModal.msgBoxOk('The file has to be a video. Please try again.')
      }
    }
  }
}
</script>
