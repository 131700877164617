<template>
  <div>
    <div :class="classFormInputContent">
      <input
        :id="'form-input-file-' + _uid"
        :ref="'uploader-' + _uid"
        class="d-none"
        type="file"
        :accept="accept"
        @change="onFileChanged"
      />
      <label :for="'form-input-file-' + _uid" class="form-upload-file">
        <div v-if="!currentFile" class="form-file-text form-file-text--placeholder">
          <span>{{ placeHolder }}</span>
          <span class="icon-delete" @click="clearFile" v-if="!hideDeleteIcon && placeHolder" />
        </div>
        <span v-if="currentFile" class="form-file-text"
          ><a target="_blank" :href="nameFile">{{ nameFile }}</a></span
        >
        <button type="button" class="btn-common btn-input-file" @click="openUploadFile">
          {{ labelButton }}<span class="icon-upload"></span>
        </button>
      </label>
    </div>

    <div class="invalid-feedback" v-if="showErrors">
      <ul class="error-content" v-if="hasErrors">
        <li
          class="error-text"
          v-for="(error, index) in errors"
          :key="`file-error-${_uid}-${index}`"
        >
          {{ error }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { formatBytes } from '@/core/utils'

export default {
  name: 'UploadFile',
  props: {
    classFormInput: {
      type: String,
      default: null
    },
    accept: {
      type: String,
      default: 'image/*'
    },
    placeHolder: {
      type: String,
      default: null
    },
    maxSize: {
      type: Number,
      default: 262144000 // 250MB
    },
    labelButton: {
      type: String,
      default: 'Upload File'
    },
    showErrors: {
      type: Boolean,
      default: true
    },
    errors: {
      type: Array,
      default: function () {
        return []
      }
    },
    currentFile: {
      type: [String, Object, FileList],
      default: ''
    },
    hideDeleteIcon: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      loading: false
    }
  },
  methods: {
    openUploadFile() {
      this.$refs['uploader-' + this._uid].click()
    },
    onFileChanged(e) {
      this.$emit('File:changed')

      const files = e.target.files || e.dataTransfer.files
      if (!files.length) {
        return
      }

      const file = files[0]

      if (file.size > this.maxSize) {
        this.$emit('error', {
          code: 'max_size_exceeded',
          message: `File must not be higher than ${formatBytes(this.maxSize)}`
        })
        return
      }
      this.$emit('file-select', files)
    },
    clearFile() {
      this.$refs['uploader-' + this._uid].value = ''
      this.$emit('clear-file')
    }
  },
  computed: {
    classFormInputContent() {
      const className = {
        'form-upload': true
      }

      className[this.classFormInput] = !!this.classFormInput
      className['form-upload-error'] = this.hasErrors

      return {
        ...className
      }
    },
    hasErrors() {
      return this.errors.length > 0
    },
    nameFile() {
      return typeof this.currentFile === 'string' ? this.currentFile : this.currentFile[0].name
    }
  }
}
</script>

<style lang="stylus" scoped>
@import "~@/core/stylus/variables.styl"
@import "~@/core/stylus/mixins.styl"
@import '~@/core/stylus/common.styl'

.invalid-feedback
  display block !important

.btn-input-file {
  background-color color-blue-100
  border 2px solid color-blue-200
  border-radius(4px)
  font font-opensans-bold
  font-size 12px
  letter-spacing 1.6px
  color color-gray-700
  text-align center
  height: 28px
  flexbox(flex)
  align-items(center)
  justify-content(center)
  padding 0 10px
  min-height unset
  text-decoration none
  text-transform capitalize

  &:focus,
  &:hover {
    box-shadow none
  }

  .icon-upload {
    padding-left 5px
  }
}

.form-upload {
  margin-bottom 10px

  &-file {
    display flex
    justify-content space-between
    align-items center
    width 100%
    border: 2px solid color-gray-400
    background-color: color-gray-500
    padding: 0 8px 0 10px
    min-height: 40px
    border-radius 7px

    .form-file-text {
      font font-opensans-regular
      font-size 12px
      overflow-wrap: break-word
      max-width: 50%
      color color-black-200

    }

    .form-file-text--placeholder {
      display: flex

      .icon-delete {
        padding 5px
        cursor pointer
      }
    }
  }
}
</style>
