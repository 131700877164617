export const state = {
  courses: [],
  pagination: {
    currentPage: 1,
    perPage: 10,
    totalRow: null
  },
  loadingCourses: true,
  courseDetail: {
    courseName: '',
    min_age: 1,
    max_age: 1,
    level: null,
    languages: null,
    subject: null,
    duration: '',
    publish: false,
    sumary: ''
  },
  defaultCourseDetail: {
    courseName: '',
    min_age: 1,
    max_age: 1,
    level: null,
    languages: null,
    subject: null,
    duration: '',
    publish: false,
    sumary: '',
    courseStudentGroupTypeId: 1,
    topic: 'none'
  },
  taskList: [],
  teacherTaskList: [],
  taskDetail: {},
  defaultTaskDetail: {
    id: null,
    course_id: '',
    task_thumbnail: '',
    task_name: '',
    essential_question: '',
    description: '',
    can_do_statement: '',
    topic: '',
    tags: [],
    teacher_description: '',
    create_prompt: '',
    respond_prompt: '',
    teacher_instructions: '',
    response_type: 1,
    order: null,
    media: [],
    links: [],
    materials: [],
    published: null,
    number_responses: 1,
    video_time_limit: 120,
    type: 'student',
    task_type: 'both_classrooms',
    task_category: 'video',
    display_students: 1,
    video_name: '',
    video_url: ''
  }
}
