<template>
  <main-layout :is-authenticate="meta.isAuthenticate">
    <div class="main-container">
      <b-col class="col-10 mx-auto">
        <ul class="nav nav-pills">
          <li class="nav-item">
            <router-link
              to="/PanelAdmin"
              class="nav-link --first"
              :class="[activeClass('CoursesList'), disabledNavLink('CoursesList')]"
              :style="disableOnLoad"
            >
              <i class="icon-book"></i><span>Course</span>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
              to="/PanelAdmin/Teacher"
              class="nav-link"
              :class="[activeClass('TeacherContent'), disabledNavLink('TeacherContent')]"
              :style="disableOnLoad"
            >
              <i class="icon-student"></i><span>Teacher</span>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
              to="/PanelAdmin/Partnership"
              class="nav-link"
              :class="[activeClass('Partnership'), disabledNavLink('Partnership')]"
              :style="disableOnLoad"
            >
              <i class="icon-global"></i><span>Partnership</span>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
              to="/PanelAdmin/subjects"
              class="nav-link"
              :class="[activeClass('Subjects'), disabledNavLink('Subjects')]"
              :style="disableOnLoad"
            >
              <i class="icon-global"></i><span>Subjects</span>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
              to="/PanelAdmin/pricing"
              class="nav-link"
              :class="[activeClass('Pricing'), disabledNavLink('Pricing')]"
              :style="disableOnLoad"
            >
              <i class="icon-global"></i><span>Pricing</span>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
              to="/PanelAdmin/purchaseReport"
              class="nav-link"
              :class="[activeClass('PurchaseReport'), disabledNavLink('PurchaseReport')]"
              :style="disableOnLoad"
            >
              <i class="icon-global"></i><span>Purchase Report</span>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
              to="/PanelAdmin/monthlyStatements"
              class="nav-link"
              :class="[activeClass('MonthlyStatements'), disabledNavLink('MonthlyStatements')]"
              :style="disableOnLoad"
            >
              <i class="icon-global"></i><span>Purchase</span>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
              to="/PanelAdmin/alerts"
              class="nav-link"
              :class="[activeClass('Alerts'), disabledNavLink('Alerts')]"
              :style="disableOnLoad"
            >
              <i class="icon-global"></i><span>Alerts</span>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
              to="/PanelAdmin/administerAdmins"
              class="nav-link"
              :class="[activeClass('AdministerAdmins'), disabledNavLink('AdministerAdmins')]"
              :style="disableOnLoad"
            >
              <i class="icon-global"></i><span>Admins</span>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
              to="/PanelAdmin/actions"
              class="nav-link"
              :class="[activeClass('ActionItems'), disabledNavLink('ActionItems')]"
              :style="disableOnLoad"
            >
              <i class="icon-global"></i><span>Actions</span>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
              to="/PanelAdmin/parentOrganization"
              class="nav-link"
              :class="[activeClass('ParentOrgList'), disabledNavLink('ParentOrgList')]"
              :style="disableOnLoad"
            >
              <i class="icon-global"></i><span>Parent Org</span>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
              to="/PanelAdmin/schools"
              class="nav-link"
              :class="[activeClass('SchoolsList'), disabledNavLink('SchoolsList')]"
              :style="disableOnLoad"
            >
              <i class="icon-global"></i><span>School</span>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
              to="/PanelAdmin/library"
              class="nav-link"
              :class="[
                activeClass('LibraryConnectionList'),
                disabledNavLink('LibraryConnectionList')
              ]"
              :style="disableOnLoad"
            >
              <i class="icon-global"></i><span>Library</span>
            </router-link>
          </li>
        </ul>
      </b-col>
      <b-col class="col-10 mx-auto">
        <div class="tab-content">
          <router-view></router-view>
        </div>
      </b-col>
    </div>
  </main-layout>
</template>

<script>
import MainLayout from '@/core/components/PageBase/MainLayout.vue'
import { UserMixins, TeacherMixins } from '@/modules/panel/Mixins'
import { AppStorage } from '@/core/utils'

export default {
  name: 'ViewAdminPanel',
  components: { MainLayout },
  mixins: [UserMixins, TeacherMixins],
  data() {
    return {
      meta: {}
    }
  },
  methods: {
    activeClass: function (...names) {
      for (const name of names) {
        if (this.$route.name === 'CoursesDetail' || this.$route.name === 'DetailCourse') {
          return name === 'CoursesList' ? 'active' : ''
        } else if (this.$route.name === 'SubjectsDetail') {
          return name === 'Subjects' ? 'active' : ''
        } else if (this.$route.name === 'PricingDetail') {
          return name === 'Pricing' ? 'active' : ''
        } else if (this.$route.name === 'AlertDetail') {
          return name === 'Alerts' ? 'active' : ''
        }
        return name === this.$route.name ? 'active' : ''
      }
    },
    disabledNavLink(tabName) {
      const isSuperAdmin = AppStorage.getUser().roles.find((rol) => rol.slug === 'super-admin')

      if (isSuperAdmin) {
        return ''
      }

      let blockedTabs = []

      AppStorage.getUser().roles.map((rol) => {
        if (rol.slug === 'customer-service-agent') {
          blockedTabs.push('TeacherContent', 'Partnership', 'SchoolsList', 'LibraryConnectionList')
        }
        if (rol.slug === 'admin-course-editor') {
          blockedTabs.push('CoursesList', 'ActionItems')
        }
        if (rol.slug === 'admin-test') {
          blockedTabs.push(
            'CoursesList',
            'TeacherContent',
            'Alerts',
            'Partnership',
            'ActionItems',
            'SchoolsList'
          )
        }
        if (rol.slug === 'super-admin') {
          blockedTabs = true
        }
      })

      return !blockedTabs.includes(tabName) && 'nav-link--disabled'
    }
  },
  computed: {
    disableOnLoad() {
      return this.isLoadingTeachers ? 'pointer-events: none' : ''
    }
  },
  watch: {
    '$route.meta': {
      handler: function (options) {
        this.meta = options
      },
      deep: true,
      immediate: true
    }
  },
  mounted() {
    this.setUser()
  },
  destroyed() {
    this.setUser()
  }
}
</script>

<style lang="stylus" scoped>
@import '~@/core/stylus/variables.styl';
@import '~@/core/stylus/common.styl';
.nav-pills .nav-link {
  color color-black-200
  background-color color-gray-301
  border 1px solid color-gray-200
  // padding 0.75rem 1.875rem
  padding 0.75rem 1.62rem

  span {
    vertical-align middle
  }

  [class^="icon-"], [class*=" icon-"] {
    vertical-align middle
    margin-right 0.32rem
    &::before {
      color color-blue-100
      font-size 1.5rem
    }
  }

  margin 0.313rem
  margin-top 0.626rem

  &.--first:not(.active) {
    margin-left 0
  }
}

.nav-link--disabled {
  pointer-events none
  cursor none
  opacity 0.6
}

.nav-pills .nav-link.active {
  border-radius 0
  position: relative
  z-index 1
  background-color: white
  color color-black-200
  font-weight bold
  border-block-end-color white
  margin-top 0
  margin-bottom: -1px
  border-top none
  margin-left 0
  padding 1.25rem 2.5rem 1.563rem 2.5rem

  .icon-book:before {
    content: "\e932"
    color color-blue-100
    font-size 1.5rem
    margin: 2px
  }

  .icon-student:before {
    content: "\e91a"
    color color-blue-100
    font-size 1.5rem
  }

  .icon-global:before {
    color color-blue-100
    font-size 1.5rem
  }
}

.tab-content {
  background-color: white
  border 1px solid color-gray-200
  padding: .5rem 2rem
  margin-bottom: 1rem
  min-height: 44rem
  width: 100%
}

.main-container {
  background-color: color-gray-900
}
</style>
