<template>
  <div>
    <div class="top">
      <a class="mx-2" href="#/PanelAdmin" @click="goBack()"><i class="icon-caret-left"></i> Back</a>
    </div>
    <b-row class="my-1">
      <b-col cols="12" lg="5" xl="4">
        <b-overlay :show="loadingCourse" spinner-medium rounded="sm">
          <b-form @submit="saveCourse" class="container-form">
            <h2 class="title">Courses</h2>
            <h6 class="subtitle"><i>Require fields *</i></h6>
            <b-form-input
              v-model="$v.getCourseDetail.courseName.$model"
              type="text"
              placeholder="Course Name *"
              :class="{
                'form-group--error': $v.getCourseDetail.courseName.$error,
                'format-input': 'format-input'
              }"
            >
            </b-form-input>
            <div
              class="error"
              v-if="!$v.getCourseDetail.courseName.required && $v.getCourseDetail.courseName.$dirty"
            >
              Course Name field is required
            </div>
            <b-form-group>
              <select-dropdown
                :options="getSubjects"
                v-model="$v.getCourseDetail.subject.$model"
                :class="{
                  'form-group--error': $v.getCourseDetail.subject.$error,
                  'format-input': 'format-input'
                }"
                placeholder="Subjects*"
                value-field="id"
              >
              </select-dropdown>
              <div
                class="error"
                v-if="!$v.getCourseDetail.subject.required && $v.getCourseDetail.subject.$dirty"
              >
                Subject field is required
              </div>
            </b-form-group>
            <b-form-group>
              <select-multiple-dropdown
                :options="getLanguages"
                v-model="$v.getCourseDetail.languages.$model"
                :class="{
                  'form-group--error': $v.getCourseDetail.languages.$error,
                  'format-input': 'format-input'
                }"
                placeholder="Language(s)*"
                value-field="id"
              >
              </select-multiple-dropdown>
              <div
                class="error"
                v-if="!$v.getCourseDetail.languages.required && $v.getCourseDetail.languages.$dirty"
              >
                Languages field is required
              </div>
            </b-form-group>
            <b-form-group>
              <select-dropdown
                :options="getCourseStudentGroupTypes"
                v-model="$v.getCourseDetail.courseStudentGroupTypeId.$model"
                :class="{
                  'form-group--error': $v.getCourseDetail.courseStudentGroupTypeId.$error,
                  'format-input': 'format-input'
                }"
                placeholder="Student Group Types *"
                value-field="id"
              >
              </select-dropdown>
              <div
                class="error"
                v-if="
                  !$v.getCourseDetail.courseStudentGroupTypeId.required &&
                  $v.getCourseDetail.courseStudentGroupTypeId.$dirty
                "
              >
                Student Group Types field is required
              </div>
            </b-form-group>
            <b-row class="my-1">
              <b-col cols="12" sm="6">
                <b-form-group
                  label-cols-sm="12"
                  label-cols-lg="12"
                  content-cols="9"
                  label="Min Age"
                  label-for="min-age-input"
                >
                  <b-form-input
                    id="min-age-input"
                    v-model="$v.getCourseDetail.min_age.$model"
                    type="number"
                    sr-only
                  >
                  </b-form-input>
                  <div
                    class="error"
                    v-if="!$v.getCourseDetail.min_age.required && $v.getCourseDetail.min_age.$dirty"
                  >
                    Min age is required
                  </div>
                </b-form-group>
              </b-col>
              <b-col cols="12" sm="6">
                <b-form-group
                  label-cols-sm="12"
                  label-cols-lg="12"
                  content-cols="9"
                  label="Max Age"
                  label-for="max-age-input"
                >
                  <b-form-input
                    id="max-age-input"
                    v-model="$v.getCourseDetail.max_age.$model"
                    type="number"
                    sr-only
                  >
                  </b-form-input>
                  <div
                    class="error"
                    v-if="!$v.getCourseDetail.max_age.required && $v.getCourseDetail.max_age.$dirty"
                  >
                    Max age is required
                  </div>
                </b-form-group>
              </b-col>
            </b-row>

            <b-form-group>
              <select-dropdown
                :options="getLevels"
                v-if="getCourseDetail.subject === 1"
                v-model="$v.getCourseDetail.level.$model"
                :class="{
                  'form-group--error': $v.getCourseDetail.level.$error,
                  'format-input': 'format-input'
                }"
                placeholder="Level*"
                value-field="id"
              >
              </select-dropdown>
              <div
                class="error"
                v-if="!$v.getCourseDetail.level.required && $v.getCourseDetail.level.$dirty"
              >
                Level field is required
              </div>
            </b-form-group>
            <b-form-input
              id="input-6"
              v-model="$v.getCourseDetail.duration.$model"
              type="number"
              placeholder="Estimated Duration (number of weeks)* "
              sr-only
            >
            </b-form-input>
            <div
              class="error"
              v-if="!$v.getCourseDetail.duration.required && $v.getCourseDetail.duration.$dirty"
            >
              Estimated duration field is required
            </div>
            <div
              class="error"
              v-if="!$v.getCourseDetail.duration.between && $v.getCourseDetail.duration.$dirty"
            >
              Estimated duration must be between 1 and 8
            </div>

            <upload-file
              place-holder="Course Summary*"
              class="form-input"
              @file-select="selectFile"
              :currentFile="$v.getCourseDetail.sumary.$model"
              @error="showErrors"
              accept="*"
            />
            <div
              class="error"
              v-if="!$v.getCourseDetail.sumary.required && $v.getCourseDetail.sumary.$dirty"
            >
              Course sumary field is required
            </div>
            <b-form-group
              label-cols-sm="12"
              label-cols-lg="12"
              label="Teacher Guide URL"
              label-for="teacher-guide"
            >
              <div class="teacher-guide-container">
                <b-form-input
                  v-model="getCourseDetail.courseGuideLink"
                  class="teacher-guide-input"
                />
                <b-button
                  class="button primary button--test-link"
                  :href="getCourseDetail.courseGuideLink"
                  target="_blank"
                  >Test Link</b-button
                >
              </div>
            </b-form-group>
            <select-dropdown
              :options="topics"
              v-model="getCourseDetail.topic"
              class="mb-4"
              placeholder="Topics"
              value-field="id"
            />
            <b-form-checkbox v-model="getCourseDetail.publish" class="custom-checkbox">
              Publish
            </b-form-checkbox>
            <br /><br />
            <section class="container-button">
              <b-button class="button b-ligth" :disabled="disableTask" @click="OpenModal()"
                >Delete</b-button
              >
              <b-button @click="goBack()" class="button b-ligth">Cancel</b-button>
              <b-button type="submit" class="button primary --sm">Save</b-button>
            </section>
          </b-form>
        </b-overlay>
      </b-col>
      <b-col cols="12" lg="7" xl="8">
        <b-overlay :show="loadingTask" spinner-medium rounded="sm">
          <task-list-table
            :course-tasks="getCourseTask"
            :disable-task="disableTask"
            ref="studentTaskList"
            @on-click-name="OpenModalDetail"
            title-table="Tasks"
          />

          <!-- <task-list-table :course-tasks="getCourseTeacherTasks"
                           :disable-task="disableTask"
                           ref="teacherTaskList"
                           @on-click-name="OpenModalDetail"
                           title-table="Teacher Tasks"/> -->
          <b-row>
            <b-col class="pb-2">
              <b-button
                class="button primary my-4 create-task-button"
                :disabled="disableTask || disableCreateTasks"
                @click="OpenModalDetail('')"
                >create new Task
              </b-button>
            </b-col>
          </b-row>
        </b-overlay>
      </b-col>
    </b-row>
    <modal ref="deleteCourse" modal-class="delete">
      <template #header> </template>
      <template #body>
        <h2 class="modal-title text-center">DELETE {{ getCourseDetail.courseName }} ?</h2>
        <p class="text-center">
          {{ getCourseDetail.courseName }} all tasks, and all task attachments will be permanently
          deleted. <br />
          Are you sure?
        </p>
      </template>
      <template #footer>
        <b-button class="button b-ligth" @click="deleteCourse"> Delete</b-button>
        <b-button class="button" @click="CloseModal"> cancel</b-button>
      </template>
    </modal>
    <modal-task ref="modalTaskDetail" :subjectType="getCourseDetail.subjectType"></modal-task>
  </div>
</template>
∫
<script>
import { SelectDropdown, SelectMultipleDropdown, UploadFile, Modal } from '@/core/components'
import ModalTask from './ModalTask'
import { CourseMixins, UtilsMixins } from '../Mixins'
import { CourseService, UploadService } from '@/core/services'
import { required, between, requiredIf } from 'vuelidate/lib/validators'
import TaskListTable from './TaskListTable.vue'

export default {
  name: 'CourseDetail',
  components: {
    SelectDropdown,
    SelectMultipleDropdown,
    UploadFile,
    Modal,
    ModalTask,
    TaskListTable
  },
  mixins: [CourseMixins, UtilsMixins],
  data() {
    return {
      loadingCourse: true,
      loadingTask: false,
      options: [
        { id: '1', name: 'Premiun' },
        { id: '0', name: 'Free' }
      ],
      subject: [],
      ages: [],
      languages: [],
      level: [],
      topics: [{ id: 'none', name: 'None' }],
      disableTask: false,
      fields: [
        {
          key: 'task_name',
          label: 'TASK NAME'
        },
        {
          key: 'topic',
          label: 'TOPIC'
        },
        {
          key: 'order',
          label: 'ORDER'
        },
        {
          key: 'visible',
          label: 'VISIBLE'
        }
      ]
    }
  },
  validations: {
    getCourseDetail: {
      courseName: {
        required
      },
      languages: {
        required
      },
      subject: {
        required
      },
      min_age: {
        required
      },
      max_age: {
        required
      },
      level: {
        required: requiredIf(function () {
          return this.getCourseDetail.subject === 1
        })
      },
      duration: {
        required,
        between: between(1, 8)
      },
      sumary: {
        required
      },
      courseStudentGroupTypeId: {
        required
      }
    }
  },
  methods: {
    saveCourse(e) {
      e.preventDefault()
      this.$v.getCourseDetail.$touch()
      if (this.$v.getCourseDetail.$anyError) {
        return
      }
      this.loadingCourse = true
      if (typeof this.getCourseDetail.sumary !== 'string') {
        UploadService.uploadFile(this.getCourseDetail.sumary[0], 1, 'sumary')
          .then((result) => {
            this.getCourseDetail.sumary = result.name
            this.save()
          })
          .catch((e) => {
            const errorMessage = e.message ? e.message : 'Error while loading course.'
            this.$bvModal.msgBoxOk(`${errorMessage} Please try again.`)
          })
      } else {
        this.save()
      }
    },
    OpenModal() {
      this.$refs.deleteCourse.showModal()
    },
    CloseModal() {
      this.$refs.deleteCourse.hideModal()
    },
    async OpenModalDetail(id) {
      this.resetTaskDetail()
      this.loadingTask = true
      const courseId = this.$route.params.id
      if (id) {
        this.fetchTaskDetails({ courseId, taskId: id })
          .then(() => {
            this.$refs.modalTaskDetail.OpenModalTask()
          })
          .catch((e) => {
            const errorMessage = e.message ? e.message : 'Error while loading course.'
            this.$bvModal.msgBoxOk(`${errorMessage} Please try again.`)
          })
          .finally(() => (this.loadingTask = false))
      } else {
        this.$refs.modalTaskDetail.removeAllAttachments()
        this.$refs.modalTaskDetail.OpenModalTask()
        this.loadingTask = false
      }
    },
    goBack() {
      this.$router.push({ name: 'CoursesList' })
    },
    deleteCourse() {
      CourseService.deleteCourse(this.$route.params.id)
        .then(() => {
          this.$router.push({ name: 'CoursesList' })
        })
        .catch((e) => {
          const errorMessage = e.message ? e.message : 'Error while deleting course.'
          this.$bvModal.msgBoxOk(`${errorMessage} Please try again.`)
        })
    },
    selectFile(files) {
      this.getCourseDetail.sumary = files
    },
    save() {
      const data = {
        subject_id: this.getCourseDetail.subject,
        name: this.getCourseDetail.courseName,
        weeks: Number(this.getCourseDetail.duration),
        pdf_url: this.getCourseDetail.sumary,
        min_age: this.getCourseDetail.min_age,
        max_age: this.getCourseDetail.max_age,
        published: this.getCourseDetail.publish,
        languages: this.getCourseDetail.languages.map((language_id) => {
          return this.getLanguages.find((o) => o.id === language_id)
        }),
        course_student_group_type_id: this.getCourseDetail.courseStudentGroupTypeId,
        course_guide_link: this.getCourseDetail.courseGuideLink,
        topic_id: this.getCourseDetail.topic !== 'none' ? this.getCourseDetail.topic : null
      }
      if (this.getCourseDetail.level) {
        data.level_id = this.getCourseDetail.level
      }
      if (this.$route.params.id) {
        CourseService.updateCourse(this.$route.params.id, data)
          .then(() => {
            this.$bvModal.msgBoxOk('Successful Upgrade!')
            this.fetchCoursesDetail(this.$route.params.id)
          })
          .catch((e) => {
            const errors = Object.values(e.errors).flat().toString().replace(/,/gs, ' ')
            const errorMessage = e.message ? e.message : 'Error while loading course.'
            this.$bvModal.msgBoxOk(`${errorMessage}
         ${errors} Please try again.`)
          })
          .finally(() => {
            this.loadingCourse = false
          })
      } else {
        CourseService.createCourse(data)
          .then((res) => {
            this.$router.push({ path: `/PanelAdmin/Course/${res.data.id}` })
            this.$bvModal.msgBoxOk('Successful Created!')
            this.disableTask = false
          })
          .catch((e) => {
            const errors = Object.values(e.errors).flat().toString().replace(/,/gs, ' ')
            const errorMessage = e.message ? e.message : 'Error while loading course.'
            this.$bvModal.msgBoxOk(`${errorMessage}
            ${errors}
            Please try again.`)
          })
          .finally(() => {
            this.loadingCourse = false
          })
      }
    },
    showErrors(err) {
      this.$bvModal.msgBoxOk(`${err.message}, Please try again`)
    },
    cutString(string) {
      return string ? (string.length > 9 ? `${string.slice(0, 9)}...` : string) : ''
    }
  },
  beforeMount() {
    this.loadingCourse = true
    this.loadingTask = true
  },
  async mounted() {
    this.loadingCourse = true
    this.disableTask = !this.$route.params.id
    CourseService.topics().then((resp) => {
      if (resp.success) {
        this.topics = [...resp.data, { id: 'none', name: 'None' }]
      } else {
        const errorMessage = resp.message ? resp.message : 'Error get topics.'
        this.$bvModal.msgBoxOk(`${errorMessage} Please try again.`, {
          size: 'sm',
          centered: true,
          dialogClass: 'text-center'
        })
      }
    })
    await Promise.all([
      this.fetchCoursesDetail(this.$route.params.id),
      this.fetchAgeRange(),
      this.fetchLanguages(),
      this.fetchLevels(),
      this.fetchSubjects(),
      this.fetchStudentGroupType()
    ]).then(function (results) {
      const data = []

      results.forEach(function (file) {
        data.push(file)
      })

      return data
    })
    this.loadingCourse = false

    if (this.$route.params.id) {
      await this.fetchTaskList(this.$route.params.id).then(() => {
        this.$refs.studentTaskList.$refs.taskList.refresh()
        // this.$refs.teacherTaskList.$refs.taskList.refresh()
      })
      this.loadingTask = false
    } else {
      this.loadingTask = false
    }
  },
  computed: {
    showPagination() {
      return this.getCourseTask.length > 10
    },
    subjectTypeSelected() {
      return this.getSubjects.find((subject) => this.getCourseDetail.subject === subject.id)?.type
    },
    disableCreateTasks() {
      return this.getCourseDetail.subjectType === 'independent_basic'
    }
  },
  destroyed() {
    this.resetCourseDetail()
  }
}
</script>

<style lang="stylus" scoped>
@import '~@/core/stylus/variables.styl';
@import '~@/core/stylus/common.styl';

a {
  color: color-blue-100
  font font-opensans-bold
  font-size: 17px
  text-decoration none
  margin-top: 0
  margin-bottom: 1rem

  .icon-caret-left {
    color: color-blue-100
  }
}

.container-form {
  width: 80%
  margin: 1px auto
}

.container-task {
  width: 100%
  margin: 1px auto
}

.container-button {
  padding: 1rem 0
  padding-top: 0
  width 90%
  margin 0

  .button {
    margin 0 2% 0 3%
    margin-top: 1rem
  }
}

.title {
  font font-opensans-bold
  font-size: 24px
  color: color-black-200
}

.subtitle {
  font-size 14px
  color color-black-300
}

.error {
  width 100%
  color red
  text-align center
  font font-opensans-bold
  font-size 11px
  margin-bottom: 10px
}

.order-input {
  height 3rem
  width 2.6rem
  text-decoration: none
  text-align: center
  cursor: pointer
  padding-left: 11px
  border: 2px solid color-gray-200
  border-radius: 4px
}

.teacher-guide-container {
  display block
  .button--test-link {
    width auto
    margin-bottom 12px
    margin-left 0
  }
  @media(min-width 500px) {
    display flex
    .button--test-link {
      margin 5px auto
      margin-left 5px
    }
  }
}

.top {
  width: 105%
  margin: 0rem 0rem 1rem -1.5rem
  padding-bottom: .5rem
  border-bottom: 2px solid red
  border-color: color-gray-400
}

.create-task-button {
  width: 100% !important;
  max-width: 300px !important;
}
</style>
