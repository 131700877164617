const adminTypes = Object.freeze({
  adminCourseEditor: 'admin-course-editor',
  customerServiceAgent: 'customer-service-agent',
  adminTest: 'admin-test',
  superAdmin: 'super-admin',
})

const groupTypes = Object.freeze({
  individual: 'individual',
  smallGroup: 'small-group',
  wholeClass: 'whole-class',
})

const taskCategories = Object.freeze({
  video: {
    id: 'video',
    name: 'Video'
  },
  activity: {
    id: 'activity',
    name: 'Activity'
  },
  introduction: {
    id: 'introduction',
    name: 'Introduction'
  },
  reflection: {
    id: 'reflection',
    name: 'Reflection'
  },
  independentPeerResponse: {
    id: 'independent_peer_response',
    name: 'Independent Peer Response'
  },
  independentNoResponse: {
    id: 'independent_no_response',
    name: 'Independent No Response'
  },
})

export {
  adminTypes,
  groupTypes,
  taskCategories
}
