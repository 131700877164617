<template>
  <div>
    <modal
      ref="detailTask"
      modal-class="modal-task-detail"
      :hideCloseButton="true"
      @hide="resetModal"
    >
      <template #header> </template>
      <template #body>
        <a @click="CloseModalTask()" class="back"><i class="icon-caret-left"></i> Back</a>
        <section class="container-body">
          <b-overlay :show="loadingTask" spinner-medium rounded="sm">
            <h2 class="modal-title text-center">
              {{ taskTitle }}
            </h2>
            <b-form>
              <!-- <custom-radio v-model="userType"
                            :options="optionsUser"/> -->
              <upload-file
                @File:changed="onFileChanged"
                @file-select="onFileLoaded"
                @error="showErrors"
                :currentFile="$v.getDetailTask.task_thumbnail.$model"
                :errors="errors.task_thumbnail"
                place-holder="Task Thumbnail*"
                class="form-input"
                :accept="accept"
              />
              <div
                class="error"
                v-if="
                  !$v.getDetailTask.task_thumbnail.required &&
                  $v.getDetailTask.task_thumbnail.$dirty
                "
              >
                Task Thumbnail field is required
              </div>

              <custom-input
                v-model="$v.getDetailTask.task_name.$model"
                id="task-input"
                label="Task Name*"
                invalid-feedback="Name field is required"
                :maxlength="254"
                :error="!$v.getDetailTask.task_name.required && $v.getDetailTask.task_name.$dirty"
              />

              <b-form-group>
                <h3 class="subtitle">Task Type</h3>
                <select-dropdown
                  :options="taskCategoriesList"
                  v-model="getDetailTask.task_category"
                  class="--secundary"
                />
              </b-form-group>
              <custom-input
                v-model="getDetailTask.topic"
                id="topic-input"
                label="Topic"
                :maxlength="254"
              />

              <b-form-group
                v-if="!isIndependentTask"
                :id="`tags-group-${_uid}`"
                :label="'Tags'"
                :label-for="`tags-${_uid}`"
                class="limit"
              >
                <b-form-tags
                  :input-id="`tags-${_uid}`"
                  v-model="getDetailTask.tags"
                  :limit="limitTags"
                  :tag-validator="tagValidator"
                  :state="state"
                  class="--secundary"
                >
                </b-form-tags>
              </b-form-group>

              <custom-input
                v-model="getDetailTask.teacher_instructions"
                id="teacher_description-input"
                type="rich-text"
                label="TEACHER INSTRUCTIONS"
                :activate-all-plugins="true"
                :maxlength="20000"
              />

              <custom-input
                v-model="getDetailTask.can_do_statement"
                id="teacher_description-input"
                type="rich-text"
                label="LEARNING OBJECTIVES"
                :activate-all-plugins="true"
                :maxlength="20000"
              />

              <b-form-checkbox
                v-model="displayStudents"
                v-if="isActivityOrReflectionTask"
                class="custom-checkbox custom-checkbox--reflection-task"
              >
                Display to Students
              </b-form-checkbox>

              <div class="top">
                <div class="student-prompt-container">
                  <h3 class="labels labels-subtitle">STUDENT PROMPT</h3>
                  <select-dropdown
                    v-if="!isIndependentTask"
                    :options="taskTypeOptions"
                    v-model="getDetailTask.task_type"
                    @inputObject="taskTypeDropdown"
                    class="classroom-dropdown --secundary"
                  />
                </div>
                <section v-if="isIndependentTask">
                  <b-form-checkbox
                    v-model="videoPrompt"
                    @change="changeIncludeVideoPrompt"
                    class="custom-checkbox mt-2 mb-3"
                  >
                    Include Video Prompt for Students
                  </b-form-checkbox>
                  <UploadVideo
                    v-if="videoPrompt"
                    class="pb-2"
                    @on-filed-changed="videoFileChanged"
                    @clear-file="clearVideoFile"
                    :file-name="getDetailTask.video_name"
                  />
                </section>
                <custom-input
                  v-model="getDetailTask.create_prompt"
                  id="create_prompt-input"
                  type="rich-text"
                  :activate-all-plugins="true"
                  :maxlength="20000"
                />

                <h3 class="labels labels--date-picker">STUDENT PROMPT DUE DATE</h3>
                <custom-date-picker
                  class-date-picker="form-datepicker-task"
                  :delete-button="!!due_date"
                  v-model="due_date"
                  :min="minStartDate"
                />

                <section
                  v-if="
                    !isActivityOrReflectionTask && !isIntroductionTask && !isIndependentNoResponse
                  "
                >
                  <div class="student-prompt-container student-prompt-container--response">
                    <h3 class="labels labels-subtitle">RESPONSE PROMPT</h3>
                    <custom-input
                      v-if="!isIndependentTask"
                      v-model="responsePromptType"
                      :readonly="true"
                    />
                  </div>
                  <custom-input
                    v-model="getDetailTask.respond_prompt"
                    id="respond_prompt-input"
                    type="rich-text"
                    :activate-all-plugins="true"
                    :maxlength="20000"
                  />
                  <h3 class="labels labels--date-picker">RESPOND PROMPT DUE DATE</h3>
                  <custom-date-picker
                    class-date-picker="form-datepicker-task"
                    :delete-button="!!response_due_date"
                    v-model="response_due_date"
                    :min="minStartDate"
                  />
                </section>
              </div>
            </b-form>

            <upload-file-attach-group
              @error="showErrors"
              v-model="getDetailTask.materials"
              @Attachment:delete="removeMaterial"
              placeholder="Student Support Materials"
            />
            <upload-file-attach-group
              v-model="getDetailTask.links"
              @Attachment:delete="removeLink"
              only-attach
              placeholder="Additional Links"
            />
            <upload-file-attach-group
              @error="showErrors"
              v-model="getDetailTask.media"
              @Attachment:delete="removeMedia"
              placeholder="Media"
              accept="video/*, image/*, audio/*"
              :is-media="allowedAdmins"
            />

            <b-form-group
              v-if="!isActivityOrReflectionTask && !isIntroductionTask && !isIndependentNoResponse"
            >
              <h3 class="labels">TASK RESPONSES</h3>
              <custom-radio v-model="getDetailTask.response_type" :options="options" />
            </b-form-group>
            <b-form-group
              v-if="!isActivityOrReflectionTask && !isIntroductionTask && !isIndependentNoResponse"
            >
              <h3 class="labels">TARGET NUMBER OF RESPONSES</h3>
              <b-row class="mx-2 my-1">
                <button @click="lessTarget()" class="icon-minus-circle b-circle"></button>
                <span class="my-2">{{ getDetailTask.number_responses }}</span>
                <button @click="plussTarget()" class="icon-plus-circle b-circle"></button>
              </b-row>
            </b-form-group>
            <b-form-group v-if="!isActivityOrReflectionTask">
              <h3 class="labels">TIME LIMIT ON VIDEO POST</h3>
              <div class="group-time">
                <b-form-input
                  v-model="getDetailTask.video_time_limit"
                  type="number"
                  :min="1"
                  :max="maxVideoTimeLimit"
                  required
                  class="--secundary group-time-input"
                  :maxlength="150"
                  @input="validate"
                >
                </b-form-input>
                <h3 class="seconds-label">Seconds</h3>
              </div>
            </b-form-group>
            <h2 v-show="errorVideoTimeLimit" class="error">{{ errorVideoTimeLimit }}</h2>
          </b-overlay>
        </section>
      </template>
      <template #footer>
        <b-button @click="OpenModalDelete" :disabled="created" class="button b-ligth">
          Delete</b-button
        >
        <b-button @click="CloseModalTask()" class="button b-ligth"> Cancel</b-button>
        <b-button @click="SaveTask" class="button"> Save</b-button>
        <b-overlay no-wrap :show="loadingTask" />
      </template>
    </modal>
    <modal ref="deleteTask" id="delete-task-modal" modal-class="delete">
      <template #header> </template>
      <template #body>
        <h2 class="modal-title text-center">DELETE {{ getDetailTask.task_name }} ?</h2>
        <p class="text-center">
          {{ getDetailTask.task_name }} and all task attachments will be permanently deleted. <br />
          are you sure?
        </p>
      </template>
      <template #footer>
        <b-button class="button b-ligth" @click="deleteTask()"> Delete</b-button>
        <b-button @click="CloseModalDelete" class="button"> Cancel</b-button>
      </template>
    </modal>
  </div>
</template>

<script>
import {
  UploadFile,
  Modal,
  UploadFileAttachGroup,
  CustomRadio,
  CustomInput,
  CustomDatePicker,
  SelectDropdown,
  UploadVideo
} from '@/core/components'
import { CourseMixins, UtilsMixins } from '../Mixins'
import { CourseService, UploadService } from '@/core/services'
import { required } from 'vuelidate/lib/validators'
import { AppStorage, adminTypes, taskCategories } from '@/core/utils'
import VideoUploadService from '@/core/services/video-upload-service'
import moment from 'moment'

export default {
  name: 'ModalTask',
  components: {
    Modal,
    UploadFile,
    UploadFileAttachGroup,
    CustomRadio,
    CustomInput,
    CustomDatePicker,
    SelectDropdown,
    UploadVideo
  },
  mixins: [CourseMixins, UtilsMixins],
  props: {
    subjectType: {
      type: String
    }
  },
  data() {
    return {
      loadingTask: false,
      options: [
        { id: 1, name: 'Video Response' },
        { id: 2, name: 'Text Response' }
      ],
      optionsUser: [
        { id: 1, name: 'Student Task', type: 'student' },
        { id: 2, name: 'Teacher Task', type: 'teacher' }
      ],
      videoPrompt: false,
      fileVideoPrompt: null,
      userType: 1,
      errors: {},
      errorVideoTimeLimit: '',
      task_thumbnail_file: '',
      limitTags: 5,
      maxLengthTag: 31,
      dirty: false,
      accept:
        'image/apng, image/bmp, image/gif, image/jpeg, image/pjpeg image/png, image/svg+xml, image/tiff, image/webp, image/x-icon',
      minStartDate: new Date(),
      due_date: null,
      response_due_date: null,
      taskTypeOptions: [
        { name: 'Both Classrooms', id: 'both_classrooms', responsePrompt: 'Both Classrooms' },
        { name: 'Classroom A', id: 'classroom_a', responsePrompt: 'Partner B' },
        { name: 'Partner B', id: 'partner_b', responsePrompt: 'Classroom A' }
      ],
      responsePromptType: 'Both Classrooms',
      maxVideoTimeLimit: 300,
      isFullscreen: false
    }
  },
  validations: {
    getDetailTask: {
      task_name: {
        required
      },
      task_thumbnail: {
        required
      }
    }
  },
  methods: {
    changeIncludeVideoPrompt(value) {
      if (!value) {
        this.clearVideoFile()
      }
    },
    videoFileChanged(file) {
      this.fileVideoPrompt = file
      this.getDetailTask.video_name = file.label
    },
    clearVideoFile() {
      this.fileVideoPrompt = {}
      this.getDetailTask.video_name = ''
      this.getDetailTask.video_url = ''
    },
    taskTypeDropdown(value) {
      this.responsePromptType = value.responsePrompt
    },
    setDueDate(date) {
      return moment(date).format('M/D/YYYY')
    },
    tagValidator(tag) {
      if (tag) {
        return tag.length < this.maxLengthTag
      }
    },
    CloseModalTask() {
      return new Promise((resolve) => {
        this.$refs.detailTask.hideModal()
        this.$v.getDetailTask.$reset()
        this.videoPrompt = false
        this.fetchTaskList(this.$route.params.id)
        resolve(this)
      })
    },
    OpenModalTask() {
      if (this.getDetailTask.id) {
        this.userType = this.optionsUser.find((opt) => opt.type === this.getDetailTask.type).id
      } else {
        if (this.getCourseDetail.courseStudentGroupTypeId === 3) {
          this.updateVideoTimeLimit(this.maxVideoTimeLimit)
        } else {
          this.updateVideoTimeLimit(120)
        }
      }
      this.errorVideoTimeLimit = ''
      this.responsePromptType = this.taskTypeOptions.find(
        (opt) => opt.id === this.getDetailTask.task_type
      )?.responsePrompt
      return new Promise((resolve) => {
        this.$refs.detailTask.showModal()
        resolve(this)
      })
    },
    OpenModalDelete() {
      this.$refs.deleteTask.showModal()
    },
    CloseModalDelete() {
      this.$refs.deleteTask.hideModal()
    },
    deleteTask() {
      this.loadingTask = true
      CourseService.deleteTask(this.$route.params.id, this.getDetailTask.id)
        .catch((e) => {
          const errorMessage = e.message ? e.message : 'Error while loading course.'
          this.$bvModal.msgBoxOk(`${errorMessage} Please try again.`)
        })
        .finally(() => {
          this.CloseModalDelete()
          this.CloseModalTask()
          this.loadingTask = false
        })
    },
    lessTarget() {
      if (this.getDetailTask.number_responses > 1) {
        this.getDetailTask.number_responses -= 1
      } else {
        this.getDetailTask.number_responses = 1
      }
    },
    plussTarget() {
      this.getDetailTask.number_responses += 1
    },
    save() {
      this.$v.getDetailTask.$touch()
      const detailTaskData = {
        ...this.getDetailTask,
        due_date: this.due_date,
        response_due_date: this.response_due_date
      }
      if (!this.isActivityOrReflectionTask) {
        detailTaskData.display_students = 1
      }
      detailTaskData.type = this.optionsUser.find((opt) => opt.id === this.userType).type
      if (!this.$v.getDetailTask.$anyError) {
        if (this.getDetailTask.id) {
          CourseService.updateTask(this.$route.params.id, this.getDetailTask.id, detailTaskData)
            .then(() => {
              this.$bvModal.msgBoxOk('Successful Upgrade!')
            })
            .catch((e) => {
              const errors = Object.values(e.errors).flat().toString().replace(/,/gs, ' ')
              const errorMessage = e.message ? e.message : 'Error while loading course.'
              this.$bvModal.msgBoxOk(`${errorMessage}
            ${errors}
            Please try again.`)
            })
            .finally(() => {
              this.loadingTask = false
              this.task_thumbnail_file = ''
              this.CloseModalTask()
            })
        } else {
          this.getDetailTask.course_id = this.$route.params.id
          CourseService.createTaskCourse(this.$route.params.id, detailTaskData)
            .then(() => {
              this.$bvModal.msgBoxOk('Successful Created!')
            })
            .catch((e) => {
              const errors = Object.values(e.errors).flat().toString().replace(/,/gs, ' ')
              const errorMessage = e.message ? e.message : 'Error while loading course.'
              this.$bvModal.msgBoxOk(`${errorMessage}
              ${errors}
              Please try again.`)
            })
            .finally(() => {
              this.loadingTask = false
              this.task_thumbnail_file = ''
              this.CloseModalTask()
            })
        }
      } else {
        this.loadingTask = false
      }
    },
    validate() {
      this.errorVideoTimeLimit = ''
      if (this.getDetailTask.video_time_limit <= 0) {
        this.errorVideoTimeLimit = 'Invalid Value must be greater than or equal to 1'
      } else if (this.getDetailTask.video_time_limit > this.maxVideoTimeLimit) {
        this.errorVideoTimeLimit = `Invalid Value must be less than or equal to ${this.maxVideoTimeLimit}`
      }
    },
    async SaveTask() {
      if (this.fileVideoPrompt) {
        this.loadingTask = true
        const resp = await VideoUploadService.videoUploadFile(
          this.fileVideoPrompt.file,
          this.fileVideoPrompt.customData,
          (progress) => {
            console.log(progress, 'progress')
          }
        )
        this.getDetailTask.video_url = resp.name
        this.loadingTask = false
      }

      const files = this.getFilesToUpload()
      if (files.length > 0) {
        UploadService.uploadMultipleFiles(files).then((result) => {
          for (let i = 0; i < result.length; i++) {
            const indexFile = result[i].index_file
            const typeFile = result[i].type_file

            if (typeFile === 'task_thumbnail') {
              this.getDetailTask.task_thumbnail = result[i].name
            } else {
              this.getDetailTask[typeFile][indexFile].url = result[i].name
              this.getDetailTask[typeFile][indexFile].original_file = result[i].originalFile
              this.getDetailTask[typeFile][indexFile].type = result[i].type
            }
          }
          this.save()
        })
      } else {
        this.save()
      }
    },
    showErrors(err) {
      this.$bvModal.msgBoxOk(`${err.message}, Please try again`)
    },
    removeAllAttachments() {
      const attachments = ['materials', 'links', 'media']
      attachments.map((item) => {
        this.getDetailTask[item].splice(0, this.getDetailTask[item].length)
      })
    },
    removeMaterial(item) {
      this.getDetailTask.materials.splice(this.getDetailTask.materials.indexOf(item), 1)
    },
    removeLink(item) {
      this.getDetailTask.links.splice(this.getDetailTask.links.indexOf(item), 1)
    },
    removeMedia(item) {
      this.getDetailTask.media.splice(this.getDetailTask.media.indexOf(item), 1)
    },
    getFilesToUpload() {
      this.loadingTask = true
      const files = []

      for (let i = 0; i < this.getDetailTask.materials.length; i++) {
        if (this.getDetailTask.materials[i].file) {
          files.push({
            file: this.getDetailTask.materials[i].file,
            index_file: i,
            type_file: 'materials'
          })
        }
      }

      for (let i = 0; i < this.getDetailTask.media.length; i++) {
        if (this.getDetailTask.media[i].file) {
          files.push({
            file: this.getDetailTask.media[i].file,
            customData: this.getDetailTask.media[i].customData,
            index_file: i,
            type_file: 'media',
            // only media will convert the videos
            isVideo: this.getDetailTask.media[i].isVideo
          })
        }
      }

      if (this.task_thumbnail_file) {
        files.push({
          file: this.task_thumbnail_file,
          index_file: 1,
          type_file: 'task_thumbnail'
        })
      }

      return files
    },
    onFileLoaded(files) {
      this.task_thumbnail_file = files[0]
      this.getDetailTask.task_thumbnail = files
    },
    onFileChanged() {
      this.errors.task_thumbnail = []
    },
    resetModal() {
      this.$v.getDetailTask.$reset()
      this.errors.task_thumbnail = []
    }
  },
  mounted() {
    this.errors.task_thumbnail = []
  },
  computed: {
    modalTaskClass() {
      return {
        'modal-task-detail': true,
        'modal-task-detail--fullscreen-editor': !!this.isFullscreen
      }
    },
    allowedAdmins() {
      return !!AppStorage.getUser().roles.find(
        (rol) => rol?.slug === adminTypes.adminCourseEditor || rol?.slug === adminTypes.superAdmin
      )
    },
    taskTitle() {
      return this.getDetailTask.id ? this.getDetailTask.task_name : 'CREATE NEW TASK'
    },
    created() {
      return this.taskTitle === 'CREATE NEW TASK'
    },
    state() {
      if (this.getDetailTask.tags.length > 0) {
        return this.dirty ? (this.getDetailTask.tags.length <= this.limitTags ? null : false) : null
      } else {
        return null
      }
    },
    displayStudents: {
      get: function () {
        return !!this.getDetailTask.display_students
      },
      set: function (value) {
        this.getDetailTask.display_students = value
      }
    },
    isActivityOrReflectionTask() {
      return (
        this.getDetailTask.task_category === 'activity' ||
        this.getDetailTask.task_category === 'reflection'
      )
    },
    isIntroductionTask() {
      return this.getDetailTask.task_category === 'introduction'
    },
    isIndependentTask() {
      return (
        this.getDetailTask.task_category === taskCategories.independentPeerResponse.id ||
        this.getDetailTask.task_category === taskCategories.independentNoResponse.id
      )
    },
    isIndependentNoResponse() {
      return this.getDetailTask.task_category === taskCategories.independentNoResponse.id
    },
    isIndependentCourse() {
      const independentSubjects = [
        'independent_basic',
        'independent_language',
        'independent_players_truest'
      ]
      return independentSubjects.includes(this.subjectType)
    },
    taskCategoriesList() {
      let categories = Object.values(taskCategories)
      if (!this.isIndependentCourse && !this.isIndependentTask) {
        categories = categories.filter(
          (category) =>
            category.id !== taskCategories.independentPeerResponse.id &&
            category.id !== taskCategories.independentNoResponse.id
        )
      }
      return categories
    }
  },
  watch: {
    getDetailTask(newValue, oldValue) {
      if (newValue.tags !== oldValue.tags) {
        this.dirty = true
        this.due_date = newValue.due_date ? newValue.due_date.split(' ')[0] : null
        this.response_due_date = newValue.response_due_date
          ? newValue.response_due_date.split(' ')[0]
          : null
      }
      if (newValue.video_url) {
        this.videoPrompt = true
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
@import '~@/core/stylus/variables.styl';
@import '~@/core/stylus/common.styl';

.b-circle {
  background-color white
  border 1px solid white
}

li.badge.badge-secundary {
  background-color white !important
  border 1px solid color-blue-200
}

a.back {
  position absolute
  left: -5px
  top: 1.8rem
  color: color-blue-100
  font font-opensans-bold
  font-size: 17px
  text-decoration none
  margin-top: -2rem !important
  padding: 5px
  margin-bottom: 1rem
  width: 100%
  border-bottom 2px solid color-gray-400

  .icon-caret-left {
    color: color-blue-100
  }
}

.subtitle
  font font-opensans-semibold

.limit {
  margin-bottom: 1rem
  border-bottom: 2px solid red
  border-color: color-gray-400
}

.top {
  margin-top: 1rem
}

.student-prompt-container {
  display: flex
  justify-content: space-between
  align-items: center
  margin-bottom: 5px
  @media (max-width 450px) {
    display: block
  }
}

.student-prompt-container--response {
  align-items: flex-start
  margin-bottom: 0
  ::v-deep {
    .form-control {
        margin-bottom: 0 !important
    }
  }
}

.classroom-dropdown {
  max-width 250px
}

.container-body {
  width: 70%
  margin: 5.25rem auto 0
}

.form-control.--secundary.area {
  overflow-y: hidden !important
}

.form-control.--secundary.group-time-input {
  height: 2rem
  padding: 0
  padding-left: 2px
  width: 3.2rem
  text-align: center
}

.group-time {
  margin-top .5rem
  display: flex

  h3 {
    margin 1px 4px
    font font-opensans-regular
    font-size 14px
    text-align: center
    color: color-black-200
  }

  .seconds-label {
    margin 5px 10px
  }
}

.labels {
  margin 2px 0
  font font-opensans-regular
  font-size 16px
  color: color-black-200
}

.labels--date-picker, .labels-subtitle {
  font: font-opensans-semibold
  margin-top: 12px
}

.modal-title.text-center {
  overflow-wrap: break-word
}

.error {
  width 100%
  text-align center
  margin-bottom 10px
  font font-opensans-bold
  font-size: 11px
  color red
}

.custom-checkbox--reflection-task {
  margin-top 20px
  margin-bottom 20px
}

::v-deep
  .form-group-attach
    .form-group-attach-links
      width 25%

@media (max-width 1100px)
  ::v-deep
    .form-group-attach
      display flex
      flex-direction column

      .form-group-attach-links
        width 30%

      .align-items-end
        display flex
        flex-direction column

        .form-upload
          margin-top 10px

        .button
          margin-top 5px
          margin-right 0 !important

@media (max-width 400px)
  .form-input
    ::v-deep
      .form-upload
        .btn-common
          height auto !important
</style>
<style lang="stylus">
//// solution for text editor scrolling when in full screen.
.modal-task-detail--fullscreen-editor
  position static !important

#delete-task-modal
  .modal-dialog
    min-width unset !important

    .modal-body
      width auto

      .close
        padding 0
</style>
